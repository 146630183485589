<div class="row btn-page">
  <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <button type="button" class="btn custom-excel-button" data-toggle="tooltip" data-placement="bottom"
      title="{{'EXECUTIVE.DOWNLOAD-STATISTICS-FOR-EACH-STUDENTS-ENTRANCE-EXAM-BY-COURSES' | translate}}"
      style="margin-bottom: 10px;float: right;" (click)="exportexcel('Usage-statistics')">
      <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
    </button>
  </div> -->
  <div class="col-xl-12 col-lg-12">

    <!-- <app-card cardTitle="{{'EXECUTIVE.STATISTICS-FOR-EACH-STUDENTS-ENTRANCE-EXAM-BY-COURSES' | translatee}}" -->
    <app-card cardClass="task-board-left" [options]="false" [hidHeader]="true">
      <div class="row align-items-center" style="justify-content: space-between;margin: 0 0;">
        <div style="padding-right: unset;width: 175px; margin-bottom: -100px; margin-top: -100px;">
          <i class="feather icon-user f-14" style="position: absolute;left: 35px;top: 28px;"></i>
          <div class="searchterm"
            style="position: relative; display: flex; align-items: center; justify-content: center; height: 100px; font-size: 14px;">
            <ng-select [ngClass]="'ng-select'" [options]="dataStudentAll" [(ngModel)]="selectedStudent"
              *ngIf="selectedStudent != null && selectedStudent != undefined && selectedStudent != ''"
              (ngModelChange)="getDataSubjectStudent($event)">
              <ng-template let-option="dataStudentAll">
                <div class="famfamfam-flags f-14 {{dataStudentAll?.value.toLowerCase()}}"></div>
                {{dataStudentAll.label}}
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div style="text-align: -webkit-right;">
          <!-- <div style="position: relative;height: 22px;width: 175px;"> -->
          <!-- <ng-select [ngClass]="'ng-select'" [options]="dataTeacherAll" [(ngModel)]="selectedTeacher"
                  *ngIf="dataTeacherAll != ''" (ngModelChange)="getDataSubjectTeacher($event)">
                  <ng-template ng-option-tmp let-option="dataTeacherAll">
                    <div class="famfamfam-flags {{dataTeacherAll?.value.toLowerCase()}}"></div>
                    {{dataTeacherAll.label}}
                  </ng-template>
                </ng-select>
                <i class="feather icon-user" style="position: absolute;left: 10px;top: 3px;"></i>
                <p *ngIf="dataTeacherAll == ''" style="text-align: center;"><b>* {{'ADMIN.NO-DATA-SHOWING' | translate}}</b>
                </p> -->

          <!-- </div> -->
          <!-- <div class="btn-cust">
                      <i class="feather icon-user" style=" display: flex; position: absolute; left: 68px;  top: 4px; "></i>
                      <ng-select [ngClass]="'ng-select'" [options]="dataTeacherAll" [(ngModel)]="selectedTeacher"
                        *ngIf="dataTeacherAll != ''" (ngModelChange)="getDataSubjectTeacher($event)">
                        <ng-template ng-option-tmp let-option="dataTeacherAll">
                          <div class="famfamfam-flags {{dataTeacherAll?.value.toLowerCase()}}"></div>
                          {{dataTeacherAll.label}}
                        </ng-template>
                      </ng-select>
                      <p *ngIf="dataTeacherAll == ''" style="text-align: center;"><b>* {{'ADMIN.NO-DATA-SHOWING' | translate}}</b>
                      </p>
                    </div> -->

          <button type="button" class="btn custom-excel-button" data-toggle="tooltip"
            title="{{'EXECUTIVE.DOWNLOAD-STATISTICS-FOR-EACH-STUDENTS-ENTRANCE-EXAM-BY-COURSES' | translate}}"
            style="float: right;" (click)="exportexcel('Usage-statistics')">
            <i class="icon feather icon-download f-16 text-c-black"></i> {{'EXECUTIVE.DOWNLOAD-EXCEL' | translate}}
          </button>
        </div>


      </div>
      <!-- <div class="input-group mb-3">
        <ng-select [ngClass]="'ng-select'" [options]="dataStudentAll" [(ngModel)]="selectedStudent"
          *ngIf="selectedStudent != null && selectedStudent != undefined && selectedStudent != ''"
          (ngModelChange)="getDataSubjectStudent($event)">
          <ng-template let-option="dataStudentAll">
            <div class="famfamfam-flags {{dataStudentAll?.value.toLowerCase()}}"></div>
            {{dataStudentAll.label}}
          </ng-template>
        </ng-select>
      </div> -->
      <div class="task-right" *ngFor="let item of dataSubject;let i = index">
        <div class="task-right-header-status" (click)="onClick(i,item.data.subjectId)">
          <div class="tab-down">
            <p style="margin-bottom: unset;">{{item.data.nameTh}}</p>
            <i class="feather icon-chevron-down float-right"></i>
          </div>
          <div id="collape" *ngIf="examDataToShowFinal == null" [ngbCollapse]="ind != i"
            class="taskboard-right-progress">
            <div class="table-responsive">
              <table class="table-version-z">
                <thead>
                  <tr style="text-align: center;color: rgb(255, 255, 255);">
                    *{{'EXECUTIVE.NO-DATA' | translate}}
                  </tr>
                </thead>
              </table>
            </div>
          </div>
          <div id="collape" *ngIf="examDataToShowFinal != null" [ngbCollapse]="ind != i"
            class="taskboard-right-progress">
            <div class="table-responsive">
              <table class="table-version-z">
                <thead>
                  <tr style="text-align: center;">
                    <th class="first">{{'EXECUTIVE.LESSON' | translate}}</th>
                    <th>{{'EXECUTIVE.LESSON-NAME' | translate}}</th>
                    <th>{{'EXECUTIVE.ALL-EXAMS' | translate}}</th>
                    <th>{{'EXECUTIVE.FAILED-THE-TEST' | translate}}</th>
                    <th class="last">{{'EXECUTIVE.PASSED-THE-TEST' | translate}}</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of examDataToShowFinal;let i = index;">
                  <tr style="text-align: center;" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">
                    <td>{{item.lessonData.sequence}}</td>
                    <td>{{item.lessonData.title}}</td>
                    <td>
                      <span>{{item.examCount}} {{'EXECUTIVE.TIMES' |translate}}</span>
                    </td>
                    <td>
                      <span>{{item.notPassCount}} {{'EXECUTIVE.TIMES'|translate}}</span>
                    </td>
                    <td>
                      <span>{{item.passCount}} {{'EXECUTIVE.TIMES' |translate}}</span>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngFor="let item of postTestDataToShow;let i = index;">
                  <tr style="text-align: center;" [ngClass]="item.index % 2 == 0 ? 'odd' : 'even'">
                    <td>-</td>
                    <td>{{item.name}}</td>
                    <td>
                      <span>{{item.count}} {{'EXECUTIVE.TIMES' |translate}}</span>
                    </td>
                    <td>
                      <span>{{item.failCount}} {{'EXECUTIVE.TIMES' |translate}}</span>
                    </td>
                    <td>
                      <span>{{item.passCount}} {{'EXECUTIVE.TIMES' |translate}}</span>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="display:flex;justify-content: center" *ngIf="dataSubject == null">
        <p><b>*{{'EXECUTIVE.NO-DATA' | translate}}</b></p>
      </div>
    </app-card>
  </div>
</div>