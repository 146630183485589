import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ExecutiveSharedDataService {

    private dataSource = new BehaviorSubject<any>('');
    getDataExecutiveLogin = this.dataSource.asObservable();

    private dataMemberExecutive = new BehaviorSubject<any>('');
    getDataMemberExecutive = this.dataMemberExecutive.asObservable();
    constructor() { }

    changeDataExecutiveLogin(val: any) {
        this.dataSource.next(val)
    }
    changeDataMemberExecutive(val: any) {
        this.dataMemberExecutive.next(val);
    }
}