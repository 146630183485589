import { Component, OnInit } from '@angular/core';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { TeaCherSharedDataService } from 'src/app/core/common/TeaCherSharedDataService';
import { Role } from 'src/app/core/models/role';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { TeacherService } from 'src/app/core/services/teacher-service/teacher.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-report-courses-income',
  templateUrl: './report-courses-income.component.html',
  styleUrls: ['./report-courses-income.component.scss'],
})
export class ReportCoursesIncomeComponent implements OnInit {
  dataLanguage: any;
  id: any;
  reportSubjectAll: any;
  topRating: any;
  dataLoading1: boolean = true;
  dataLoading2: boolean = true;
  dataTeacherAll: Array<any> = [];
  selectedTeacher: any = '';
  localRole: any;
  dataStudent: any = [];
  Cousestd: boolean = false;
  stdCousePrice: any;
  totalSubject: any;
  TotalLastMonth: any;
  TotalThisMonth: any;
  sumtotal: any;
  sumlast: any;
  sumthis: any;
  topBest: any;
  topWorst: any;
  IncomeMonth1: any;
  IncomeMonth2: any;

  searchModel: string = '';
  pageTotal: number = 10;
  page: number = 1;
  pageCount: number = 0;
  constructor(
    private adminService: AdminService,
    private adminSharedDataService: AdminSharedDataService,
    private teaCherSharedDataService: TeaCherSharedDataService,
    private teacherService: TeacherService
  ) { }

  ngOnInit() {
    this.getLanguage();
    var local = localStorage.getItem('currentUserLogin');
    if (local != null) {
      var dataLocal = JSON.parse(local);
      this.localRole = dataLocal.role;
      if (dataLocal.role == Role.SuperAdmin || dataLocal.role == Role.Admin) {
        this.id = dataLocal.id;
        this.getTeacher();
      } else if (dataLocal.role == Role.Teacher) {
        this.id = dataLocal.id;
        this.getDataStudent();
        this.getTotalInSubjectList();
        this.getTopten();
      } else if (dataLocal.role == Role.Executive) {
        this.id = dataLocal.id;
        this.getTeacher();
      } else {
      }
    }
  }
  getLanguage() {
    this.adminSharedDataService.getDataLanguage.subscribe(async (message) => {
      if (message == '') {
        this.dataLanguage = localStorage.getItem('lang');
      } else {
        this.dataLanguage = message;
      }
    })
  }

  getTopten() {
    var topten: any;
    this.adminService.FindTopIncomeSubjectList(this.id).then((res) => {
      topten = res;
      if (topten.status == true) {
        if (
          (topten.topBest != null &&
            topten.topBest != undefined &&
            topten.topBest != '') ||
          (topten.topWorst != null &&
            topten.topWorst != undefined &&
            topten.topWorst != '')
        ) {
          this.topBest = topten;
          this.topWorst = topten;
          this.dataLoading2 = false;
        } else {
          this.topBest = null;
          this.topWorst = null;
          this.dataLoading2 = false;
        }
      } else {
        this.topBest = null;
        this.topWorst = null;
        this.dataLoading2 = false;
      }
    });
  }

  getTeacher() {
    var getSuccess: any;
    var data: any = [];
    this.adminService.fetchDataAllMemmerUser().then((response) => {
      getSuccess = response;

      for (let i = 0; i < getSuccess.dataTeachers.length; i++) {
        data.push({
          value: getSuccess.dataTeachers[i].por.tchId,
          label: getSuccess.dataTeachers[i].por.fullNameTh,
        });
      }
      this.dataTeacherAll = data;

      this.selectedTeacher = this.dataTeacherAll[0].value;
      this.getDataSubjectTeacher(this.selectedTeacher);
    });
  }

  getDataSubjectTeacher(idTeacher: any) {
    this.id = idTeacher;
    if (idTeacher != null) {
      this.dataLoading1 = true;
      this.dataLoading2 = true;
      // this.dataLoading2 = true;
      this.getDataStudent();
      this.getTotalInSubjectList();
      this.getTopten();
    }
  }
  dataStudentOrigin: any = [];
  dataStudentFilterOrigin: any = [];
  getDataStudent() {
    var data = [];
    this.adminService.FindSudentBuyCourses(this.id).then((res) => {
      var success = res;
      // console.log("dataStudent---->", success);
      if (success.status == true) {
        for (let i = 0; i < success.data.length; i++) {
          if (success.data[i].statusPay == 'successful') {
            data.push(success.data[i]);
          }
        }
        this.dataStudent = data;
        this.dataStudent = this.dataStudent.map((item: any, index: number) => ({ ...item, index: index + 1 }));
        this.dataStudentOrigin = this.dataStudent;
        this.dataStudentFilterOrigin = this.dataStudent;
        this.pageCount = Math.ceil(this.dataStudentOrigin.length / this.pageTotal);
        this.dataStudent = this.dataStudent.slice(0, this.pageTotal);
        this.dataLoading1 = false;
      } else {
        this.dataStudent = null;
        this.dataLoading1 = false;
      }
    });
  }

  getTotalInSubjectList() {
    this.sumtotal = 0;
    this.sumlast = 0;
    this.sumthis = 0;
    var date1: any;
    this.adminService.FindIncomeTotalInSubjectList(this.id).then(
      (res) => {
        var totallsub = res;
        if (res.status == true) {
          this.totalSubject = totallsub;
          if (this.totalSubject.all.length != 0) {
            for (let i = 0; i < this.totalSubject.all.length; i++) {
              this.sumtotal += this.totalSubject.all[i].totalPrice;
            }
          } else {
            this.sumtotal = 0;
          }

          for (let i = 0; i < res.lastMonth.length; i++) {
            this.sumlast += res.lastMonth[i].totalPrice;
          }
          for (let i = 0; i < res.thisMonth.length; i++) {
            this.sumthis += res.thisMonth[i].totalPrice;
          }
          if (this.totalSubject.thisMonth != null && this.totalSubject.thisMonth != '') {
            date1 = new Date(this.totalSubject.thisMonth[0].date);
          } else {
            date1 = new Date(Date.now());
          }
          const result1 = date1.toLocaleDateString('th-TH', {
            month: 'long',
          });
          this.IncomeMonth1 = result1;

          if (this.totalSubject.lastMonth.length != 0) {
            const date2 = new Date(this.totalSubject.lastMonth[0].date);

            const result2 = date2.toLocaleDateString('th-TH', {
              month: 'long',
            });
            this.IncomeMonth2 = result2;

          } else {
            const date2 = new Date(this.totalSubject.thisMonth[0].date);
            var mounth = date2.getMonth();
            var day = date2.getDay();
            var year = date2.getFullYear();
            var result = new Date(year + '-' + mounth + '-' + day);
            const result2 = result.toLocaleDateString('th-TH', {
              month: 'long',
            });
            this.IncomeMonth2 = result2;

          }
        }
      },
      (err) => {
        console.log('err : ', err);
      }
    );
  }
  searchChange(Event: string) {
    Event = Event.toLowerCase();
    this.dataStudent = this.dataStudentOrigin
    this.dataStudent = this.dataStudent.filter((item: any) => item.dataStudent.fullNameTh.toLowerCase().includes(Event) || item.dataStudent.email.toLowerCase().includes(Event) || item.dataSubject.nameTh.toLowerCase().includes(Event)
    );
    this.dataStudentFilterOrigin = this.dataStudent;
    this.pageCount = Math.ceil(this.dataStudent.length / this.pageTotal);
    this.dataStudent = this.dataStudent.slice(0, this.pageTotal);
  }
  pageChange(Directive: string) {
    if (this.page >= 1 && this.page < this.pageCount && Directive == 'next' || this.page <= this.pageCount && this.page > 1 && Directive == 'prev') {
      this.dataStudent = !this.searchModel ? this.dataStudentOrigin : this.dataStudentFilterOrigin;
      if (Directive == 'prev') this.page--;
      else this.page++;
      this.dataStudent = !this.searchModel ? this.dataStudentOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal) : this.dataStudentFilterOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal);
    }
  }
  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }
}
