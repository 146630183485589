<app-card [hidHeader]="true">
  <!-- <div class="dt-responsive table-responsive" *ngIf="dataSatisfactionQuestionnaire != undefined ">
          <table datatable class="table table-striped row-border table-hover" *ngIf="!dataLoading">
            <thead>
              <tr>
                <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.CLASSIFICATION-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.TEACHER-NAME' | translate}}</th>
                <th>{{'EXECUTIVE.AMOUNT-OF-PEOPLE-ANSWERING-THE-QUESTION' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of dataSatisfactionQuestionnaire;let i = index"
                (click)="openViewSummary(item.subjectData.subjectId)">
                <td>
                  <div class="d-inline-block align-middle">
                    <img src="{{baseURL}}{{item.subjectData.images}}" alt="user image"
                      class="img-radius align-top m-r-15" style="width:40px;height: 40px;object-fit: cover;"
                      onerror="this.src='assets/images/no-img.png'">
                    <div class="d-inline-block">
                      <h6 class="m-b-0">{{item.subjectData.name_th}}</h6>
                      <p class="m-b-0">{{item.subjectData.name_en}}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    <h6 class="m-b-0">{{item.subjectData.category.nameTh}}</h6>
                    <p class="m-b-0">{{item.subjectData.category.nameEn}}</p>
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    <h6 class="m-b-0">{{item.subjectData.teacher.fullNameTh}}</h6>
                    <p class="m-b-0">{{item.subjectData.teacher.fullNameEn}}</p>
                  </div>
                </td>
                <td>
                  <span style="font-size: 18px;" class="badge badge-light-success"><strong>{{item.respondents}}
                      {{'EXECUTIVE.PERSON' | translate}}</strong></span>
                  <div class="overlay-edit">
                    <button type="button" class="btn btn-icon btn-success"><i
                        class="feather icon-eye"></i></button>{{'EXECUTIVE.SEE-MORE' | translate}}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="text-align: center;margin: 20px;">
          <div class="spinner-border text-info" role="status" *ngIf="dataLoading">
            <span class="sr-only">{{'EXECUTIVE.LOADING' | translate}}...</span>
          </div>
        </div> -->
  <!-- <div class="row" style="display: flex;justify-content: center;">
          <img src="assets/images/icon-gif/1_zVnWJtyGOX_kUIDm6ccCfQ.gif" style="width: 350px;" alt="">
        </div>
        <div class="row" style="display: flex;justify-content: center;">
          <h2>----- อยู่ระหว่างการพัฒนา -----</h2>
        </div> -->
  <div class="row" style="justify-content: space-between;margin: unset;padding-bottom: 15px;">
    <div class="search-barrr">
      <input type="text" [(ngModel)]="searchModel" (ngModelChange)="searchChange($event)"
        placeholder="{{'TEACHER.Q-AND-A.SEARCH' | translate}}...">
      <i class="feather icon-search"></i>
    </div>
  </div>
  <div class="table-scroll-x">
    <table class="table-version-z">
      <thead>
        <tr>
          <th class="first text-center">{{'ADMIN.SUBJECT.NO' | translate}}</th>

          <th>{{'EXECUTIVE.COURES-NAME' | translate}}</th>
          <th>{{'EXECUTIVE.CLASSIFICATION-NAME' | translate}}</th>
          <th>{{'EXECUTIVE.TEACHER-NAME' | translate}}</th>
          <th class="text-center">{{'EXECUTIVE.AMOUNT-OF-PEOPLE-ANSWERING-THE-QUESTION' | translate}}</th>
          <th class="last text-center">{{'EXECUTIVE.SELECTION' | translate}}</th>

        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="dataSatisfaction.length && !dataLoading">

          <tr *ngFor="let itemDataProblem of dataSatisfaction;index
                  as i" [ngClass]="i % 2 == 0 ? 'odd' : 'even'">

            <!-- <td>
                    <div class="d-inline-block align-middle">
                      <img src="{{baseURL}}{{itemDataProblem.subjectData.images}}" alt="user image"
                        class="img-radius align-top m-r-15" style="width:40px;height: 40px;object-fit: cover;"
                        onerror="this.src='assets/images/no-img.png'">
                      <div class="d-inline-block">
                        <h6 class="m-b-0">{{itemDataProblem.subjectData.name_th}}</h6>
                        <p class="m-b-0">{{itemDataProblem.subjectData.name_en}}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-inline-block">
                      <h6 class="m-b-0">{{itemDataProblem.subjectData.category.nameTh}}</h6>
                      <p class="m-b-0">{{itemDataProblem.subjectData.category.nameEn}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d-inline-block">
                      <h6 class="m-b-0">{{itemDataProblem.subjectData.teacher.fullNameTh}}</h6>
                      <p class="m-b-0">{{itemDataProblem.subjectData.teacher.fullNameEn}}</p>
                    </div>
                  </td>
                  <td>
                    <span style="font-size: 18px;"
                      class="badge badge-light-success"><strong>{{itemDataProblem.respondents}}
                        {{'EXECUTIVE.PERSON' | translate}}</strong></span>
                    <div class="overlay-edit">
                      <button type="button" class="btn btn-icon btn-success"><i
                          class="feather icon-eye"></i></button>{{'EXECUTIVE.SEE-MORE' | translate}}
                    </div>
                  </td> -->

            <td class="text-center">{{itemDataProblem.index}}</td>

            <td style="text-align: left;">

              <div class="d-inline-block align-middle">
                <img src="{{baseURL}}{{itemDataProblem.subjectData.images}}" alt="user image" class="img-radius m-r-15"
                  style="width:40px;height: 40px;object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
                <div class="d-inline-block">
                  <p class="m-b-0">
                    {{dataLanguage=='th'? itemDataProblem.subjectData.name_th:itemDataProblem.subjectData.name_en}}
                  </p>
                </div>
              </div>
            </td>
            <td style="text-overflow: ellipsis;overflow: hidden;width: 200px;text-align: left;">

              {{dataLanguage=='th'?itemDataProblem.subjectData.category.nameTh:itemDataProblem.subjectData.category.nameEn}}


            </td>
            <td style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;width: 200px;text-align: left;">

              {{dataLanguage=='th'?itemDataProblem.subjectData.teacher.fullNameTh:itemDataProblem.subjectData.teacher.fullNameEn}}


            </td>
            <!-- <td class="text-center"><span style="font-size: 18px;"
                      class="badge badge-light-success"><strong>{{itemDataProblem.respondents}}
                        {{'EXECUTIVE.PERSON' | translate}}</strong></span></td> -->
            <td class="text-center"><span class="badge badge-pill badge-light-true">{{itemDataProblem.respondents}}
                {{'EXECUTIVE.PERSON' | translate}}</span></td>
            <td class="text-center">
              <!-- <span type="button" (click)="viewProblembyid(itemDataProblem)"> -->
              <span type="button" (click)="openViewSummary(itemDataProblem.subjectData.subjectId)">

                <i class="icon feather icon-eye f-16 text-i-view"></i></span>
            </td>

          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="pageCount" class="page-control">
    <p style="padding-right: 15px;">{{'ADMIN.Q-AND-A.PAGE' | translate}} <span class="ml-1 mr-1">{{page}}</span>
      {{'ADMIN.Q-AND-A.OF' |
      translate}} <span class="ml-1">{{pageCount}}</span></p>
    <div>
      <button (click)="pageChange('prev')" class="prev">{{'ADMIN.Q-AND-A.PREVIOUS' | translate}}</button>
      <button (click)="pageChange('next')" class="next">{{'ADMIN.Q-AND-A.NEXT' | translate}}</button>
    </div>
  </div>

</app-card>
<app-view-summary></app-view-summary>