<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto" (click)="onClickNoni()">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="35"
          height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" class="feather feather-bell">
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg> -->
        <span>
          <i class="feather icon-bell"></i>
        </span>
        <span class="badge bg-danger" *ngIf="dataNoti.length > 0">
          <span class="sr-only"></span>
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right
        notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">แจ้งเตือน</h6>
          <!-- <div class="float-right">
            <a href="javascript:" class="m-r-10">ทำเครื่องหมายว่าอ่านแล้ว</a>
            <a href="javascript:">ลบทั้งหมด</a>
          </div> -->
        </div>
        <ul class="noti-body" *ngIf="dataNoti.length > 0">
          <!-- <li class="n-title">
            <p class="m-b-0">ใหม่</p>
          </li>
          <li class="notification">
            <div class="media">
              <img class="img-radius" src="assets/images/user/avatar-1.jpg" alt="Generic placeholder image">
              <div class="media-body">
                <p><strong>จอร์น ชาวไร่</strong><span class="n-time text-muted"><i
                      class="icon feather icon-clock m-r-10"></i>30 นาที</span></p>
                <p>เพิ่มข้อมูลหลักสูตร</p>
              </div>
            </div>
          </li>
          <li class="n-title">
            <p class="m-b-0">ก่อนหน้านี้</p>
          </li> -->
          <li class="notification" *ngFor="let item of dataNoti" (click)="onClickOpenPage()">
            <div class="media">
              <img class="img-radius" src="{{baseURL}}{{item.img}}" alt="student
                image" height="40" style="object-fit: cover;" onerror="this.src='assets/images/no-img.png'">
              <div class="media-body">
                <p><strong>{{item.name}}</strong><span class="n-time
                    text-muted"><i class="icon feather icon-clock m-r-10"></i>{{item.date |
                    date:'short'}}</span></p>
                <p>{{item.content}}</p>
              </div>
            </div>
          </li>
        </ul>
        <!-- <div class="noti-footer">
          <a href="javascript:">ดูทั้งหมด</a>
        </div> -->
        <div class="noti-footer" *ngIf="dataNoti.length <= 0">
          <a href="javascript:">ไม่มีข้อมูล</a>
        </div>
      </div>
    </div>
  </li>
  <!-- <li (click)="this.visibleUserList = !this.visibleUserList;">
    <div class="dropdown">
      <a href="javascript:" class="displayChatbox dropdown-toggle">
        <i class="icon feather icon-mail"></i><span class="badge bg-success"><span class="sr-only"></span></span>
      </a>
    </div>
  </li> -->
  <li class="nav-item">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <img class="flag-img-c" *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="20">
        <img class="flag-img-c" *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="20">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <ul class="pro-body">
          <li *ngFor="let item of listLang">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="20">
              <span class="align-middle" style="padding-left: 10px;">{{item.text}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <!-- <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-person-circle"
          viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
          <path fill-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
        </svg>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head" *ngIf="localRole != null">
          <span>{{localRole.username}}</span><br>
          <a (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li *ngIf="localRole.role != 'Teacher' && localRole.role !=
            'Executive'"><a [routerLink]="['/admin/profile']" class="dropdown-item"><i class="feather icon-user"></i>
              โปรไฟล์</a></li>
          <li *ngIf="localRole.role == 'Teacher'"><a [routerLink]="['/teacher/profile']" class="dropdown-item"><i
                class="feather icon-user"></i> โปรไฟล์</a></li>
          <li *ngIf="localRole.role == 'Executive'"><a [routerLink]="['/executive/profile']" class="dropdown-item"><i
                class="feather icon-user"></i> โปรไฟล์</a></li>
          <li ><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> ข้อความ</a></li>
        </ul>
        <div class="pro-head" *ngIf="localRole != null" style="margin-bottom: -25px;">
          <p style="font-size: 10px;margin-top: -9px;text-align: center;">Designed By A CHAT SOCIAL CO., LTD.</p>
        </div>
      </div>
    </div>
  </li> -->

  <li class="nav-item">

    <div class="dropdown drp-user" ngbDropdown placement="auto">

      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" class="userinfo">
        <!-- userimage -->
        <div class="d-flex">
          <div>
            <img class="avatar" *ngIf="localRole.role === 'Teacher'" src="{{urlImage}}{{imgTeacher}}">
            <img class="avatar" *ngIf="localRole.role === 'Admin' ||localRole.role === 'SuperAdmin'"
              src="assets/images/admin.png">
            <img class="avatar"
              *ngIf="localRole.role !== 'Teacher' && localRole.role !== 'Admin'&&localRole.role !== 'SuperAdmin'"
              src="{{urlImage}}{{imgExecutive}}">
          </div>
          <div class="d-grid" style="display: grid;line-height: 1;align-items: center;">
            <span *ngIf="localRole.role === 'Admin'"
              style="font-weight: bold; font-size: 14px; color: rgba(74, 76, 86, 1);">{{localRole.username}}</span>
            <span *ngIf="localRole.role === 'Teacher' && dataTeacher!=undefined"
              style="font-weight: bold; font-size: 14px; color: rgba(74, 76, 86, 1);">{{dataTeacher?
              dataTeacher.result.username:''}}</span>
            <span *ngIf="localRole.role === 'Executive'"
              style="font-weight: bold; font-size: 14px; color: rgba(74, 76, 86, 1);">{{dataExecutive?
              dataExecutive.por.username:''}}</span>
            <span style="font-weight: bold; font-size: 12px; color: rgba(140, 144, 165, 1);"
              *ngIf="localRole.role!='SuperAdmin'">({{localRole.role}})</span>
            <span style="font-weight: bold; font-size: 12px; color: rgba(140, 144, 165, 1);"
              *ngIf="localRole.role=='SuperAdmin'">{{localRole.role}}</span>

          </div>

        </div>



      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head" *ngIf="localRole != null">

          <span>{{localRole.username}}</span><br>
          <a (click)="logout()" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">

          <li *ngIf="localRole.role == 'Admin'">
            <a [routerLink]="['/admin/profile']" class="dropdown-item">
              <i class="feather icon-user icon-user"></i> โปรไฟล์
            </a>
          </li>
          <!-- <li *ngIf="localRole.role != 'Teacher' && localRole.role != 'Executive'">
            <a [routerLink]="['/admin/profile']" class="dropdown-item">
              <i class="feather icon-user icon-user"></i> โปรไฟล์
            </a>
          </li> -->
          <li *ngIf="localRole.role == 'Teacher'">
            <a [routerLink]="['/teacher/profile']" class="dropdown-item">
              <i class="feather icon-user icon-user"></i> โปรไฟล์
            </a>
          </li>
          <li *ngIf="localRole.role == 'Executive'">
            <a [routerLink]="['/executive/profile']" class="dropdown-item">
              <i class="feather icon-user icon-user"></i> โปรไฟล์
            </a>
          </li>
        </ul>
        <div class="pro-head" *ngIf="localRole != null" style="margin-bottom: -25px;">
          <p style="font-size: 10px; margin-top: -9px; text-align: center;">Designed By A CHAT SOCIAL CO., LTD.</p>
        </div>
      </div>
    </div>
  </li>
</ul>

<section class="header-user-list" *ngIf="visibleUserList &&
  !this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList= !this.visibleUserList;
    this.chatMessage= false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage &&
  !this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage= !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList &&
  this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList= !this.visibleUserList;
    this.chatMessage= false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage &&
  this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage= !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>
<ng-snotify [ngClass]="style"></ng-snotify>