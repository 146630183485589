import { Component, OnInit, ViewChild } from '@angular/core';
import { ChildActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AdminSharedDataService } from './core/common/adminSharedDataService';
import { TeaCherSharedDataService } from './core/common/TeaCherSharedDataService';
import { ExecutiveSharedDataService } from './core/common/ExecutiveSharedDataservice';
import { SigninInterface } from './core/interfaces/auth-interface/signin-interface';
import { Role } from './core/models/role';
import { AdminService } from './core/services/admin-service/admin.service';
import { AuthService } from './core/services/auth/auth.service';
import { TeacherService } from './core/services/teacher-service/teacher.service';
import { Socket } from 'ngx-socket-io';
import { SnotifyPosition, SnotifyService, SnotifyToastConfig } from 'ng-snotify';
import { LanguageService } from './core/services/language-service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  dataLocal: SigninInterface;

  newTop = true;
  blockMax = 6;
  dockMax = 8;
  bodyMaxLength = 80;
  titleMaxLength = 15;
  backdrop = -1;
  position: SnotifyPosition = SnotifyPosition.rightTop;
  timeout = 5000;
  progressBar = true;
  closeClick = true;
  pauseHover = true;
  title = 'Snotify title!';
  body = 'Lorem ipsum dolor sit amet!';
  style = 'material';
  constructor(
    private adminService: AdminService,
    private router: Router,
    private authService: AuthService,
    private adminSharedDataService: AdminSharedDataService,
    private teaCherSharedDataService: TeaCherSharedDataService,
    private teacherService: TeacherService,
    private snotifyService: SnotifyService, public languageService: LanguageService) {
    router.events.forEach((event) => {

      if (event instanceof NavigationEnd) {
        var languageValue = localStorage.getItem("lang");
        setTimeout(() => this.languageService.setLanguage(languageValue), 500);


        if (event['urlAfterRedirects'] == '/auth/signin') {
          var local = localStorage.getItem("currentUserLogin");
          if (local != null) {
            var dataLocal = JSON.parse(local);
            if (dataLocal.role == Role.SuperAdmin || dataLocal.role == Role.Admin) {
              this.router.navigate([`/admin/dashboard`], { replaceUrl: true });
            } else if (dataLocal.role == Role.Teacher) {
              this.router.navigate([`/teacher/dashboard`], { replaceUrl: true });
            } else if (dataLocal.role == Role.Executive) {
              this.router.navigate([`/executive/dashboard`], { replaceUrl: true });
            } else {

            }
          }
        }
      }

      if (event instanceof NavigationStart) {

        if (event['url'] == '/auth/errorAnth' || event['url'] == '/auth/errorConnectApi' || event['url'] == '/auth/signin') {
          this.checkLogin_();
        }

        if (event['url'] != '/teacher/courses/quiz/add-quiz') {
          localStorage.removeItem("quizs_1");
          localStorage.removeItem("quizs_2");
        }

        if (event['url'] != '/admin/add-form-quiz') {
          localStorage.removeItem("quizs_level-test");
        }

      }


    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.loadDataNoti();
  }


  loadDataNoti() {

    var local = localStorage.getItem("currentUserLogin");
    var dataLocal = JSON.parse(local);
    if (dataLocal != null) {
      if (dataLocal.role == Role.Teacher) {
        this.teacherService.findCheckSubjectiveExam().then(response => {
          if (response.status) {
            this.teaCherSharedDataService.changeCheckSubjectiveExam(response.data);
          }
        }, err => {
          console.log("err : ", err);
        })
      } else {
        if (dataLocal.role == Role.Admin || dataLocal.role == Role.SuperAdmin) {
          this.adminService.FindTransferByStatusPay("pending").then(response => {
            if (response.status) {
              this.teaCherSharedDataService.changeTransferByStatusPayPending(response.data);
            }
          });
        }
      }
    }

  }



  checkLogin_() {
    var dataResponse: any;
    this.authService.checkLogin().then(response => {
      dataResponse = response;
      if (dataResponse.message == "Login-success") {
        var local = localStorage.getItem("currentUserLogin");
        if (local != null) {
          this.dataLocal = JSON.parse(local);
          if (this.dataLocal.role == Role.SuperAdmin || this.dataLocal.role == Role.Admin) {
            this.router.navigate([`/admin/dashboard`], { replaceUrl: true });
          } else if (this.dataLocal.role == Role.Teacher) {
            this.router.navigate([`/teacher/dashboard`], { replaceUrl: true });
          } else if (this.dataLocal.role == Role.Executive) {
            this.router.navigate([`/executive/dashboard`], { replaceUrl: true });
          } else {
            localStorage.removeItem("currentUserLogin");
            localStorage.removeItem("select_portal");
            this.router.navigate([`/auth/signin`], { replaceUrl: true });
          }
        } else {
          this.router.navigate([`/auth/signin`], { replaceUrl: true });
        }
      }

    }, err => {
      console.log("err : ", err);
      // token หมดอายุ
      if (err.statusText === "Unauthorized") {
        var dataLoin = JSON.parse(localStorage.getItem('currentUserLogin'));
        if (dataLoin != null) {
          this.router.navigate([`/auth/errorAnth`], { replaceUrl: true });
        } else {
          this.router.navigate([`/auth/signin`], { replaceUrl: true });
        }
      }

      // เชื่อมต่อ api ไม่ได้
      if (err.statusText === "Unknown Error" || err.statusText === "Not Found") {
        this.router.navigate([`/auth/errorConnectApi`], { replaceUrl: true });
      }

    })
  }


  getConfig(): SnotifyToastConfig {
    this.snotifyService.setDefaults({
      global: {
        newOnTop: this.newTop,
        maxAtPosition: this.blockMax,
        maxOnScreen: this.dockMax,
        // @ts-ignore
        filterDuplicates: this.filterDuplicates,
      },
    });
    return {
      bodyMaxLength: this.bodyMaxLength,
      titleMaxLength: this.titleMaxLength,
      backdrop: this.backdrop,
      position: this.position,
      timeout: this.timeout,
      showProgressBar: this.progressBar,
      closeOnClick: this.closeClick,
      pauseOnHover: this.pauseHover,
    };
  }


}