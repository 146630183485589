import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss']
})
export class ReportProblemComponent implements OnInit {
  public singleGallery: any;
  // dataProblem: any;
  dataProblemFilter: any;
  dataView: any;
  baseURL = Constants.REST_API_MAIN;
  dataLoading: boolean = true;
  type: string;
  startDate: any;
  endDate: any;

  itemDataProblem: any;

  dataProblem1: any[] = [];
  dataProblemFilter1: any[] = [];


  dataProblem: any = [];
  dataProblemOrigin: any = [];
  dataProblemFilterOrigin: any = [];


  activeButton: string = 'all';
  searchTermUser: string = '';
  filteredUser: any[] = [];
  pageLength: number = 10;
  currentPage: number = 1;


  searchModel: string = '';
  pageTotal: number = 10;
  page: number = 1;
  pageCount: number = 0;
  @ViewChild('myLargeModalLabel') myLargeModalLabel;

  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
  }

  ngOnInit() {
    this.findReportProblem();
    this.onChangSelectPortal();
  }

  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.findReportProblem();
      }
    });
  }

  findReportProblem() {
    var vv = []
    this.dataLoading = true;
    this.adminService.FindReportProblem().then(res => {
      // console.log(res);

      if (res.status == true) {
        // this.dataProblem = res.data;
        // this.dataProblem1 = res.data;
        // this.dataProblemFilter1 = res.data;
        // this.dataProblemFilter = res.data;
        // this.dataLoading = false;


        this.dataProblem = res.data;
        this.dataProblem = this.dataProblem.map((item: any, index: number) => ({ ...item, index: index + 1 }));
        this.dataProblemOrigin = this.dataProblem;
        this.dataProblemFilterOrigin = this.dataProblem;
        this.pageCount = Math.ceil(this.dataProblemOrigin.length / this.pageTotal);
        this.dataProblem = this.dataProblem.slice(0, this.pageTotal);
        this.dataLoading = false;

        // console.log(this.dataProblem, this.dataProblemOrigin, this.dataProblemFilterOrigin, this.pageCount);
      } else {
        this.dataProblemFilter = null;
        this.dataProblem = null;
        this.dataLoading = false;
      }
      this.filteredUser = this.dataProblem;
      // console.log("-->", this.filteredUser);
      // for (let i = 0; i < this.dataProblem1.length; i++) {
      //   vv.push(this.dataProblem1[i].date);
      // }

    }, error => {
      console.log("error : ", error);
    })

  }
  filterUser() {
    if (this.searchTermUser) {
      this.filteredUser = this.dataProblem.filter(item =>
        item.title.toLowerCase().includes(this.searchTermUser.toLowerCase()) ||
        item.dataStudent.fullNameEn.toLowerCase().includes(this.searchTermUser.toLowerCase()) ||
        item.dataStudent.fullNameTh.toLowerCase().includes(this.searchTermUser.toLowerCase())
      )
    } else {
      this.filteredUser = this.dataProblem;
    }
  }
  get paginatedUser() {
    const startIndex = (this.currentPage - 1) * this.pageLength;
    const endIndex = this.currentPage * this.pageLength;
    return this.filteredUser.slice(startIndex, endIndex);

  }
  updatePagination() {
    return Math.ceil(this.filteredUser.length / this.pageLength);
  }
  setPage(page: number) {
    if (page >= 1 && page <= this.updatePagination()) {
      this.currentPage = page;
    }
  }
  dataViewImg: any;
  viewProblembyid(data: any) {
    this.myLargeModalLabel.show();
    this.dataView = data;
    // console.log("==>", this.dataView);

    var nameImg = data.img.split('\\');
    // var nameImg = data.img.replace(/\\/g, '/');
    // console.log("nameimg", nameImg);

    if (nameImg != undefined && data.img != '') {
      var dataImg = nameImg[4];
      this.type = nameImg[3];
      // console.log("if", dataImg);
    }
    this.dataViewImg = nameImg.split(',');

    this.singleGallery = [
      {
        src: this.baseURL + '/' + nameImg,
        caption: dataImg,
        thumb: this.baseURL + '/' + nameImg,
      },
    ];
    // console.log("gallery->", this.singleGallery);

  }

  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

  clearDateAll() {
    this.startDate = document.getElementById('start');
    this.endDate = document.getElementById('end');
    this.findReportProblem();
  }

  clearDateEnd() {
    this.endDate = "YYYY-MM-DD";
  }

  // searchDate(dateEnd: any) {
  //   this.dataProblem = this.dataProblemFilterOrigin;
  //   var filterDataDate = this.dataProblem.filter(f => moment(f.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
  //   this.dataProblem = filterDataDate;
  //   this.dataProblem = this.dataProblem.slice(0, this.pageTotal);

  // }

  setDateRange(range: string) {
    this.activeButton = range;
    const today = moment();
    let start;
    let end = today;

    switch (range) {
      case 'yesterday':
        start = today.clone().subtract(1, 'days');
        break;
      case 'last7':
        start = today.clone().subtract(7, 'days');
        break;
      case 'last15':
        start = today.clone().subtract(15, 'days');
        // console.log(start)
        break;
      case 'last30':
        start = today.clone().subtract(30, 'days');
        break;
      default:
        start = today;
    }

    this.startDate = start.format('YYYY-MM-DD');
    this.endDate = end.format('YYYY-MM-DD');

    // Trigger your search function here if needed
    this.searchDateNew();
  }

  searchDateNew() {
    // console.log("==>", this.dataProblemFilter1);
    const filterDataDate = this.dataProblemFilterOrigin.filter(f =>

      moment(f.date).isSameOrAfter(this.startDate) &&
      moment(f.date).isSameOrBefore(this.endDate)
    );
    this.dataProblem = filterDataDate;
    this.dataProblem = this.dataProblem.slice(0, this.pageTotal);
  }

  clearDateAllNew() {
    this.activeButton = 'all';
    this.startDate = '';
    this.endDate = '';
    this.dataProblem = this.dataProblemFilterOrigin;
    this.findReportProblem();
  }





  searchChange(Event: string) {
    Event = Event.toLowerCase();
    this.dataProblem = this.dataProblemOrigin;
    this.dataProblem = this.dataProblem.filter((item: any) => item.dataStudent.fullNameTh.toLowerCase().includes(Event) || item.dataStudent.fullNameEn.toLowerCase().includes(Event));
    // this.dataProblem = this.dataProblem.filter((item: any) => item.title.toLowerCase().includes(Event));
    this.dataProblemFilterOrigin = this.dataProblem;
    this.pageCount = Math.ceil(this.dataProblem.length / this.pageTotal);
    // console.log(this.dataProblem.slice(0, this.pageTotal), "ssssssssssssssssssssssssssssss")
    this.dataProblem = this.dataProblem.slice(0, this.pageTotal);

  }

  pageChange(Directive: string) {
    if (this.page >= 1 && this.page < this.pageCount && Directive == 'next' || this.page <= this.pageCount && this.page > 1 && Directive == 'prev') {
      this.dataProblem = !this.searchModel ? this.dataProblemOrigin : this.dataProblemFilterOrigin;
      if (Directive == 'prev') this.page--;
      else this.page++;
      this.dataProblem = !this.searchModel ? this.dataProblemOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal) : this.dataProblemFilterOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal);
    }
  }
}
