<body>
  <app-navigation class="pcoded-navbar"
    [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.gradientConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
    (onNavMobCollapse)="navMobClick()"></app-navigation>
  <app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light"
    (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavHeaderMobCollapse)="navMobClick()"></app-nav-bar>
  <div class="pcoded-main-container">

    <div class="pcoded-wrapper"
      [ngClass]="{'container': this.gradientConfig.layout === 'horizontal' && this.gradientConfig.subLayout === 'horizontal-2'}">
      <div class="pcoded-content">
        <div class="pcoded-inner-content">
          <div class="main-body">
            <app-breadcrumb></app-breadcrumb>

            <div class="page-wrapper">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-configuration></app-configuration>

</body>