<ng-container *ngIf="this.navigationList">
  <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
    <div class="page-header" *ngIf="last && breadcrumb.breadcrumbs !== false">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
                <div class="head-line" *ngIf="last">
                  <div class="icon"><i class="feather icon-home"
                      style="color: #ffffff;margin-right:15px;font-size: 20px;"></i></div>
                  <div class="line"></div>
                  <!-- <span><i class="feather icon-home" style="color: #ffffff;"></i></span>
                  <hr class="m-b-10" style="font-size: 18px;"> -->
                </div>
              </ng-container>
            </div>
            <ul class="breadcrumb">
              <!-- <li class="breadcrumb-item">
                <a *ngIf="type === 'theme2'"><i class="feather icon-home" style="color: #ffffff;"></i></a>
                <a *ngIf="type === 'theme1'">Home</a>
              </li> -->
              <ng-container *ngFor="let breadcrumb of navigationList" style="margin-bottom: 10px;">
                <li class="breadcrumb-item" *ngIf="breadcrumb.url !== false"><a
                    [routerLink]="breadcrumb.url">{{breadcrumb.title | translate}}</a></li>
                <li class="breadcrumb-item" *ngIf="breadcrumb.url === false"><a href="javascript:">{{breadcrumb.title |
                    translate}}</a></li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
</ng-container>