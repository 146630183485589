import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import { ViewSummaryComponent } from './view-summary/view-summary.component';

@Component({
  selector: 'app-report-satisfaction',
  templateUrl: './report-satisfaction.component.html',
  styleUrls: ['./report-satisfaction.component.scss']
})
export class ReportSatisfactionComponent implements OnInit {
  @ViewChild(ViewSummaryComponent) childViewSummary: ViewSummaryComponent;
  dataLanguage: any;
  baseURL = Constants.REST_API_MAIN;
  userData: any;
  adminId: string = '';
  responseSubject: any;
  dataSatisfactionQuestionnaire: any;

  datafindQuestion: any;
  dataLoading: boolean = true;

  itemDataSatisfaction: any;


  dataSatisfaction: any = [];
  dataSatisfactionOrigin: any = [];
  dataSatisfactionFilterOrigin: any = [];


  searchModel: string = '';
  pageTotal: number = 10;
  page: number = 1;
  pageCount: number = 0;



  constructor(
    private adminService: AdminService, private adminSharedDataService: AdminSharedDataService
  ) { }

  ngOnInit() {
    this.loadAllSubject();
    this.findSatisfindQuestion();
  }
  getDataLanguage() {
    this.adminSharedDataService.getDataLanguage.subscribe(async (message) => {
      if (message == '') {
        this.dataLanguage = localStorage.getItem('lang');
      } else {
        this.dataLanguage = message;
      }
    })
  }

  loadAllSubject() {
    this.adminService.GetAllSubject().then((response) => {
      this.responseSubject = response.dataSubject;
    });
  }

  findSatisfindQuestion() {
    this.adminService.FindSatisfindQuestion().then((res) => {
      this.datafindQuestion = res.data;
      this.loadDataSatisfactionQuestionnaire();
    });
  }

  async loadDataSatisfactionQuestionnaire() {
    this.dataLoading = true;
    var dataGroupCouse: any = [];
    var array = [];
    await this.adminService.FindSatisfactionQuestionnaire().then(response => {
      if (response.status) {
        for (let index = 0; index < response.data.length; index++) {
          array.push(response.data[index].score[0].score5 +
            response.data[index].score[0].score4 +
            response.data[index].score[0].score3 +
            response.data[index].score[0].score2 +
            response.data[index].score[0].score1
          )
          if (response.data.length != 0) {
            var idSub = this.responseSubject.find(x => x.subjectId == response.data[index].subjectID);
            dataGroupCouse.push({ 'subjectData': idSub, 'surveyData': response.data[index].score, 'respondents': array[index] });
          }
        }
        // this.dataSatisfactionQuestionnaire = dataGroupCouse;
        this.dataSatisfaction = dataGroupCouse;

        this.dataSatisfaction = this.dataSatisfaction.map((item: any, index: number) => ({ ...item, index: index + 1 }));
        this.dataSatisfactionOrigin = this.dataSatisfaction;
        this.dataSatisfactionFilterOrigin = this.dataSatisfaction;
        this.pageCount = Math.ceil(this.dataSatisfactionOrigin.length / this.pageTotal);
        this.dataSatisfaction = this.dataSatisfaction.slice(0, this.pageTotal);
        this.dataLoading = false;
        // setTimeout(() => {
        this.dataLoading = false;
        // }, 1000); 
      } else {
        // setTimeout(() => {
        this.dataLoading = false;
        // }, 1000);  
      }
      setTimeout(() => {
        this.dataSatisfactionQuestionnaire = dataGroupCouse;
      }, 500);
    }, error => {
      console.log("error : ", error);
    });
  }

  openViewSummary(subjectId) {
    this.childViewSummary.openViewSummary(subjectId, this.dataSatisfactionQuestionnaire);
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }





  searchChange(Event: string) {
    Event = Event.toLowerCase();
    this.dataSatisfaction = this.dataSatisfactionOrigin;
    this.dataSatisfaction = this.dataSatisfaction.filter((item: any) => item.subjectData.name_th.toLowerCase().includes(Event) || item.subjectData.name_en.toLowerCase().includes(Event));
    // this.dataSatisfaction = this.dataSatisfaction.filter((item: any) => item.title.toLowerCase().includes(Event));
    this.dataSatisfactionFilterOrigin = this.dataSatisfaction;
    this.pageCount = Math.ceil(this.dataSatisfaction.length / this.pageTotal);
    this.dataSatisfaction = this.dataSatisfaction.slice(0, this.pageTotal);

  }

  pageChange(Directive: string) {
    if (this.page >= 1 && this.page < this.pageCount && Directive == 'next' || this.page <= this.pageCount && this.page > 1 && Directive == 'prev') {
      this.dataSatisfaction = !this.searchModel ? this.dataSatisfactionOrigin : this.dataSatisfactionFilterOrigin;
      if (Directive == 'prev') this.page--;
      else this.page++;
      this.dataSatisfaction = !this.searchModel ? this.dataSatisfactionOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal) : this.dataSatisfactionFilterOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal);
    }
  }
}
