import { Constants } from '../../constants/constant';
import { Injectable } from '@angular/core';
import { ServiceManagerService } from '../service-manager.service';
import { AuthenInterface } from '../../interfaces/auth-interface/authen-interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private serviceManagerService: ServiceManagerService) { }

  AuthenLogin(dataLogin: AuthenInterface) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/authenticate`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_AUTH(API_URL, dataLogin).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  checkLogin() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Auth`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAdmin(adminID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataAdmin?adminID=${adminID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataTeacher(teacherID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataTeacher?teacherID=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataExecutive(executiveID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataExecutive?executiveID=${executiveID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


}
