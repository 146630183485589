import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/core/constants/constant';
import { AdminService } from 'src/app/core/services/admin-service/admin.service';
import * as XLSX from 'xlsx';
// import { useState } from "react";
import * as moment from 'moment';
import { AdminSharedDataService } from 'src/app/core/common/adminSharedDataService';
import { Role } from 'src/app/core/models/role';

@Component({
  selector: 'app-report-user-register',
  templateUrl: './report-user-register.component.html',
  styleUrls: ['./report-user-register.component.scss']
})

export class ReportUserRegisterComponent implements OnInit {
  urlImage = Constants.REST_API_MAIN;
  dataLoading1: boolean = true;
  dataTeacher: any;
  dataStudent: any;
  openTeacher: boolean = true;
  openStudent: boolean = false;
  selectUser = 'ผู้สอน';
  TeacherAndStudent: any;
  Reportuser: any;
  ReportuserStudent: any;
  title = 'angular-app';
  startDate: any;
  endDate: any;
  activeButton: string = 'all';
  noData: boolean = false;
  dataLanguage: any;
  searchTermUserRegister: string = '';
  searchTermUserStudent: string = '';
  filteredUserRegister: any[] = [];
  filteredUserStudent: any[] = [];
  pageLength: number = 10;
  currentPage: number = 1;

  userType: number = 0;
  users: any[] = [];
  usersOrigin: any[] = [];
  usersFilterOrigin: any[] = [];
  searchModel: string = '';
  pageTotal: number = 10;
  page: number = 1;
  pageCount: number = 0;
  localRole: any;

  constructor(private adminService: AdminService, private adminSharedDataService: AdminSharedDataService) {
  }

  ngOnInit() {
    this.getRole()
    this.getTeacherAndstudent();
    this.onChangSelectPortal();
    this.getDataLanguage();
  }

  getRole() {
    var local = localStorage.getItem('currentUserLogin');
    if (local != null) {
      var dataLocal = JSON.parse(local);
      this.localRole = dataLocal.role;

    }
  }
  getDataLanguage() {
    this.adminSharedDataService.getDataLanguage.subscribe(async (message) => {
      if (message == '') {
        this.dataLanguage = localStorage.getItem('lang');
      } else {
        this.dataLanguage = message;
      }
    })
  }
  onChangSelectPortal() {
    this.adminSharedDataService.getDataelectPortal.subscribe((message) => {
      if (message != "") {
        this.getTeacherAndstudent();
      }
    });
  }

  clickOpenTeacherAndStudent() {
    this.dataLoading1 = true;
    if (this.selectUser == 'ผู้สอน') {
      this.startDate = document.getElementById('start');
      this.endDate = document.getElementById('end');
      // this.dataLoading1 = true;
      this.Reportuser = this.TeacherAndStudent.dataTeachers;
      this.openTeacher = true;
      this.openStudent = false;
      // setTimeout(() => {
      //   this.dataLoading1 = false;
      // }, 1000);
    } else {
      this.startDate = document.getElementById('start');
      this.endDate = document.getElementById('end');
      // this.dataLoading1 = true;
      this.Reportuser = this.TeacherAndStudent.dataStudents;
      this.openTeacher = false;
      this.openStudent = true;
    }
    setTimeout(() => {
      this.dataLoading1 = false;
    }, 500);

  }

  // clearDateAll() {
  //   this.startDate = document.getElementById('start');
  //   this.endDate = document.getElementById('end');
  //   this.getTeacherAndstudent();
  // }

  // clearDateEnd() {
  //   this.endDate = "YYYY-MM-DD";
  // }
  // searchDate(dateEnd: any) {
  //   var filterDataDate: any;
  //   if (this.selectUser == 'ผู้สอน') {
  //     filterDataDate = this.Reportuser.filter(f => moment(f.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
  //     this.TeacherAndStudent.dataTeachers = filterDataDate;
  //   } else {
  //     filterDataDate = this.Reportuser.filter(f => moment(f.dataStudents.dateCreate).format("YYYY-MM-DD") >= this.startDate && moment(f.dataStudents.dateCreate).format("YYYY-MM-DD") <= dateEnd.target.value);
  //     this.TeacherAndStudent.dataStudents = filterDataDate;
  //   }

  // }

  async getTeacherAndstudent() {
    var teacherandstd: any;
    this.adminService.fetchDataAllMemmerUser().then((res) => {
      teacherandstd = res;
      this.TeacherAndStudent = teacherandstd;
      this.Reportuser = this.TeacherAndStudent.dataTeachers;
      this.ReportuserStudent = this.TeacherAndStudent.dataStudents;
      this.dataLoading1 = true;
      setTimeout(() => {
        this.dataLoading1 = false;
      }, 1000);
      this.filteredUserRegister = this.Reportuser;
      this.filteredUserStudent = this.ReportuserStudent;
      // New //
      if (this.userType <= 0) this.users = this.Reportuser;
      else this.users = this.ReportuserStudent;
      this.users = this.users.map((item, index) => ({ ...item, index: index + 1 }));

      this.usersOrigin = this.users;
      this.usersFilterOrigin = this.users;
      this.pageCount = Math.ceil(this.usersOrigin.length / this.pageTotal);
      this.users = this.usersOrigin.slice(0, this.pageTotal);

    })

  }

  async userTypeChange(Event: number) {
    this.userType = Event;
    if (this.userType <= 0) this.users = this.Reportuser;
    else this.users = this.ReportuserStudent;
    this.users = this.users.map((item, index) => ({ ...item, index: index + 1 }));
    this.usersOrigin = this.users;
    this.usersFilterOrigin = this.users;
    this.pageCount = Math.ceil(this.usersOrigin.length / this.pageTotal);
    this.users = this.usersOrigin.slice(0, this.pageTotal);

    this.searchModel = '';
  }

  searchChange(Event: string) {
    Event = Event.toLowerCase();
    this.users = this.usersOrigin;
    if (this.userType <= 0) this.users = this.users.filter((item: any) => item.por.fullNameEn.toLowerCase().includes(Event) || item.por.fullNameTh.toLowerCase().includes(Event));
    else this.users = this.users.filter((item: any) => item.dataStudents.fullNameEn.toLowerCase().includes(Event) || item.dataStudents.fullNameTh.toLowerCase().includes(Event));
    this.usersFilterOrigin = this.users;
    this.pageCount = Math.ceil(this.users.length / this.pageTotal);
    this.users = this.users.slice(0, this.pageTotal);
    this.activeButton = 'all';
  }

  pageChange(Directive: string) {
    if (this.page >= 1 && this.page < this.pageCount && Directive == 'next' || this.page <= this.pageCount && this.page > 1 && Directive == 'prev') {
      this.users = !this.searchModel ? this.usersOrigin : this.usersFilterOrigin;
      if (Directive == 'prev') this.page--;
      else this.page++;
      this.users = !this.searchModel ? this.usersOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal) : this.usersFilterOrigin.slice((this.page - 1) * this.pageTotal, (this.page - 1) * this.pageTotal + this.pageTotal);
    }
  }

  tabChange(Event: string) {
    this.activeButton = Event;
  }

  filterUserRegister() {
    if (this.openTeacher == false) {
      if (this.searchTermUserStudent) {
        this.filteredUserStudent = this.ReportuserStudent.filter(x =>
          x.dataStudents.fullNameTh.toLowerCase().includes(this.searchTermUserStudent.toLowerCase()) ||
          x.dataStudents.fullNameEn.toLowerCase().includes(this.searchTermUserStudent.toLowerCase()) ||
          x.dataStudents.username.toLowerCase().includes(this.searchTermUserStudent.toLowerCase()) ||
          x.dataStudents.email.toLowerCase().includes(this.searchTermUserStudent.toLowerCase()) ||
          x.dataStudents.phoneNumber.includes(this.searchTermUserStudent)
        )
      } else {
        this.filteredUserStudent = this.ReportuserStudent;
      }
    } else {
      if (this.searchTermUserRegister) {
        this.filteredUserRegister = this.Reportuser.filter(item =>
          item.por.fullNameTh.toLowerCase().includes(this.searchTermUserRegister.toLowerCase()) ||
          item.por.fullNameEn.toLowerCase().includes(this.searchTermUserRegister.toLowerCase()) ||
          item.por.username.toLowerCase().includes(this.searchTermUserRegister.toLowerCase()) ||
          item.por.email.toLowerCase().includes(this.searchTermUserRegister.toLowerCase()) ||
          item.por.phoneNumber.toLowerCase().includes(this.searchTermUserRegister.toLowerCase())
        )
      } else {
        this.filteredUserRegister = this.Reportuser;
      }
    }


  }
  get paginatedUserRegister() {
    const startIndex = (this.currentPage - 1) * this.pageLength;
    const endIndex = this.currentPage * this.pageLength;
    return this.filteredUserRegister.slice(startIndex, endIndex);

  }
  updatePagination() {
    return Math.ceil(this.filteredUserRegister.length / this.pageLength);
  }
  setPage(page: number) {
    if (page >= 1 && page <= this.updatePagination()) {
      this.currentPage = page;
    }
  }
  setDateRange(range: string) {
    this.activeButton = range;
    const today = moment();
    let start;
    let end = today;

    switch (range) {
      case 'yesterday':
        start = today.clone().subtract(1, 'days');
        break;
      case 'last7':
        start = today.clone().subtract(7, 'days');
        break;
      case 'last15':
        start = today.clone().subtract(15, 'days');
        break;
      case 'last30':
        start = today.clone().subtract(30, 'days');
        break;
      default:
        start = today;
    }

    this.startDate = start.format('YYYY-MM-DD');
    this.endDate = end.format('YYYY-MM-DD');

    // Trigger your search function here if needed
    this.searchDateNew();
  }

  searchDateNew() {
    let filterDataDate: any[];
    // if (this.selectUser === 'ผู้สอน') {
    //   filterDataDate = this.Reportuser.filter(f =>
    //     moment(f.por.dateCreate).isSameOrAfter(this.startDate) &&
    //     moment(f.por.dateCreate).isSameOrBefore(this.endDate)
    //   );
    //   this.TeacherAndStudent.dataTeachers = filterDataDate;
    // } else {
    //   filterDataDate = this.Reportuser.filter(f =>
    //     moment(f.dataStudents.dateCreate).isSameOrAfter(this.startDate) &&
    //     moment(f.dataStudents.dateCreate).isSameOrBefore(this.endDate)
    //   );
    //   this.TeacherAndStudent.dataStudents = filterDataDate;
    // }
    if (this.userType <= 0) {
      filterDataDate = this.usersOrigin.filter(f =>
        moment(f.por.dateCreate).isSameOrAfter(this.startDate) &&
        moment(f.por.dateCreate).isSameOrBefore(this.endDate)
      );
    } else {
      filterDataDate = this.usersOrigin.filter(f =>
        moment(f.dataStudents.dateCreate).isSameOrAfter(this.startDate) &&
        moment(f.dataStudents.dateCreate).isSameOrBefore(this.endDate)
      );
    }
    this.usersFilterOrigin = filterDataDate;
    this.users = filterDataDate;
    this.pageCount = Math.ceil(this.users.length / this.pageTotal);
    this.users = this.users.slice(0, this.pageTotal);
  }

  dateAllNew() {
    this.activeButton = 'all';
    this.startDate = '';
    this.endDate = '';
    this.userTypeChange(this.userType);
    // this.getTeacherAndstudent();
  }

  exportexcel(type: string): void {
    var fileName = `${type}.xlsx`;
    /* pass here the table id */
    let element = document.getElementById(`excel-table-${type}`);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);

  }

}
