import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs"

@Injectable()
export class TeaCherSharedDataService {

  private dataSource = new BehaviorSubject<any>('');
  getDataTeaCherLogin = this.dataSource.asObservable();

  private dataSubjectAll = new BehaviorSubject<any>('');
  getDataSubjectAll = this.dataSubjectAll.asObservable();

  private dataSourceExamAndQuestion = new BehaviorSubject<any>('');
  getDataExamAndQuestion = this.dataSourceExamAndQuestion.asObservable();

  private dataSourceCheckSubjectiveExam = new BehaviorSubject<any>('');
  getDataCheckSubjectiveExam = this.dataSourceCheckSubjectiveExam.asObservable();

  private dataSourceTransferByStatusPayPending = new BehaviorSubject<any>('');
  getDataTransferByStatusPayPending = this.dataSourceTransferByStatusPayPending.asObservable();

  private dataSourceProgressUploadFile = new BehaviorSubject<any>(0);
  getdataSourceProgressUploadFile = this.dataSourceProgressUploadFile.asObservable();

  private dataLanguage = new BehaviorSubject<string>('');
  getDataLanguage = this.dataLanguage.asObservable();

  private dataMemberTeacher = new BehaviorSubject<any>('');
  getDataMemberTeacher = this.dataMemberTeacher.asObservable();

  constructor() { }

  changeDataTeaCherLogin(val: any) {
    this.dataSource.next(val)
  }

  changeDataSubjectAll(val: any) {
    this.dataSubjectAll.next(val)
  }

  changeExamAndQuestion(val: any) {
    this.dataSourceExamAndQuestion.next(val)
  }

  changeCheckSubjectiveExam(val: any) {
    this.dataSourceCheckSubjectiveExam.next(val)
  }

  changeTransferByStatusPayPending(val: any) {
    this.dataSourceTransferByStatusPayPending.next(val)
  }

  changeUploadfileProgress(val: any) {
    this.dataSourceProgressUploadFile.next(val)
  }
  changeDataLanguage(val: string) {
    this.dataLanguage.next(val)
  }
  changeDataMemberTeacher(data: any) {
    this.dataMemberTeacher.next(data)
  }
}