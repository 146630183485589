import { Constants } from '../../constants/constant';
import { Injectable } from '@angular/core';
import { ServiceManagerService } from '../service-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ExecutiveService {

  // getZipCode(idTambons: any) {
  //   throw new Error('Method not implemented.');
  // }

  constructor(private serviceManagerService: ServiceManagerService) { }

  fetchDataAllMemmerUser() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FetchDataMembers`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateExecutive(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateExecutive`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddress() {
    let API_URL = `${Constants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceDropdownSelect`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddressDistrict(idprovince: any) {
    let API_URL = `${Constants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictDropdownSelect?idDistrict=${idprovince}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddressSubDistrict(idsubDistrict: any) {
    let API_URL = `${Constants.REST_API_SW}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictDropdownSelect?idSubDistrict=${idsubDistrict}`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getZipCode(idTambons: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/ZipCode?idTambons=${idTambons}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
}
